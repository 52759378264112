import { Close, Info } from "@mui/icons-material";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { InfoPanelSectionConfig } from "../../utils/infoPanelUtils/infoPanelConfig";
import { InfoPanelContext } from "../../utils/infoPanelUtils/infoPanelContext";
import * as Styled from "./infoPanelStyles";
import ReusableComponents from "./reusableInfoPanelComponents";

const COMPONENT_NAME = "InfoPanel";

/**
 * The props for the component
 */
interface Props {
	/**
	 * The open state of the drawer
	 */
	isOpen: boolean;
	/**
	 * The set state for the parent to toggle the open state of the drawer
	 */
	setIsOpen: (newOpenState: boolean) => void;
}

/**
 * The main implementation of the info panel
 */
const InfoPanel: React.FC<Props> = (props) => {
	const infoPanelContext = React.useContext(InfoPanelContext);

	const [openSection, setOpenSection] = React.useState<string | undefined>(undefined);

	React.useEffect(() => {
		setOpenSection(infoPanelContext.openSection);
	}, [infoPanelContext.openSection]);

	/**
	 * Helper to render section content and nested sections
	 * @param section the section to render
	 * @returns the rendered content and children
	 */
	const renderSectionContent = (section: InfoPanelSectionConfig) => {
		return (
			<>
				{section.content}
				{section.nestedSections && renderSections(section.nestedSections)}
			</>
		);
	};

	/**
	 * Renders a non expanding info panel section and children
	 * @param section the section to render
	 * @returns returns a non expanding section
	 */
	const renderSection = (section: InfoPanelSectionConfig, index: number) => {
		return (
			<Box display="flex" flexDirection="column" sx={{ padding: Styled.defaultPadding }}>
				<Typography variant="h6">{section.displayTitle ?? section.title}</Typography>
				{renderSectionContent(section)}
			</Box>
		);
	};

	/**
	 * Renders a accordion info panel section and children
	 * @param section the section to render
	 * @returns returns an accordion section
	 */
	const renderSectionAccordion = (section: InfoPanelSectionConfig, index: number) => {
		return (
			<ReusableComponents.InfoPanelAccordion
				title={section.displayTitle ?? section.title}
				content={renderSectionContent(section)}
				isDense={true}
				isOpen={
					openSection === section.title ||
					(section.nestedSections &&
						_.some(
							section.nestedSections,
							(childSection: InfoPanelSectionConfig) =>
								childSection.title === openSection
						))
				}
			/>
		);
	};

	/**
	 * Renders sections for each section in info panel config
	 * @param sections the sections to render
	 * @returns the rendered sections
	 */
	const renderSections = (sections: Array<InfoPanelSectionConfig>): React.ReactNode => {
		return (
			<>
				{_.map(sections, (section, index) => {
					return section.isAccordion ? (
						<div key={section.title + index}>
							{renderSectionAccordion(section, index)}
						</div>
					) : (
						<div key={section.title + index}>{renderSection(section, index)}</div>
					);
				})}
			</>
		);
	};

	return (
		<Styled.InfoPanel color="inherit" id="info-drawer" isOpen={props.isOpen}>
			<Styled.ExpandedPanel>
				<Box display="flex" flexDirection="column" sx={{ height: "100%" }}>
					<Styled.Header>
						<Typography variant="h5" sx={{ fontWeight: "bold" }}>
							{infoPanelContext.title}
						</Typography>
						<IconButton onClick={() => props.setIsOpen(false)}>
							<Close />
						</IconButton>
					</Styled.Header>
					<Divider />
					{renderSections(infoPanelContext.sections)}
				</Box>
			</Styled.ExpandedPanel>
			<Styled.PersistentPanel>
				<IconButton color={"primary"} onClick={() => props.setIsOpen(!props.isOpen)}>
					<Info />
				</IconButton>
			</Styled.PersistentPanel>
		</Styled.InfoPanel>
	);
};

InfoPanel.displayName = COMPONENT_NAME;

export default InfoPanel;
